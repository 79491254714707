/* Aeonik */
@font-face {
  font-family: "Aeonik";
  src: url("./Aeonik-Thin.woff2") format("woff2"), url("./Aeonik-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik";
  src: url("./Aeonik-ThinItalic.woff2") format("woff2"), url("./Aeonik-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik";
  src: url("./Aeonik-Light.woff2") format("woff2"), url("./Aeonik-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik";
  src: url("./Aeonik-LightItalic.woff2") format("woff2"), url("./Aeonik-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik";
  src: url("./Aeonik-Regular.woff2") format("woff2"), url("./Aeonik-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik";
  src: url("./Aeonik-RegularItalic.woff2") format("woff2"), url("./Aeonik-RegularItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik";
  src: url("./Aeonik-Medium.woff2") format("woff2"), url("./Aeonik-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik";
  src: url("./Aeonik-MediumItalic.woff2") format("woff2"), url("./Aeonik-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik";
  src: url("./Aeonik-Bold.woff2") format("woff2"), url("./Aeonik-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik";
  src: url("./Aeonik-BoldItalic.woff2") format("woff2"), url("./Aeonik-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik";
  src: url("./Aeonik-Black.woff2") format("woff2"), url("./Aeonik-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik";
  src: url("./Aeonik-BlackItalic.woff2") format("woff2"), url("./Aeonik-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik Air";
  src: url("./Aeonik-Air.woff2") format("woff2"), url("./Aeonik-Air.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik Air";
  src: url("./Aeonik-AirItalic.woff2") format("woff2"), url("./Aeonik-AirItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik Fono";
  src: url("./AeonikFono-Thin.woff2") format("woff2"), url("./AeonikFono-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik Fono";
  src: url("./AeonikFono-Light.woff2") format("woff2"), url("./AeonikFono-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik Fono";
  src: url("./AeonikFono-Regular.woff2") format("woff2"), url("./AeonikFono-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik Fono";
  src: url("./AeonikFono-Medium.woff2") format("woff2"), url("./AeonikFono-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik Fono";
  src: url("./AeonikFono-Bold.woff2") format("woff2"), url("./AeonikFono-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik Fono";
  src: url("./AeonikFono-Black.woff2") format("woff2"), url("./AeonikFono-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aeonik Fono Air";
  src: url("./AeonikFono-Air.woff2") format("woff2"), url("./AeonikFono-Air.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

/* roobert */
@font-face {
  font-family: "Roobert";
  src: url("./Roobert-LightItalic.woff2") format("woff2"), url("./Roobert-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roobert";
  src: url("./Roobert-Medium.woff2") format("woff2"), url("./Roobert-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roobert";
  src: url("./Roobert-BoldItalic.woff2") format("woff2"), url("./Roobert-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roobert";
  src: url("./Roobert-HeavyItalic.woff2") format("woff2"), url("./Roobert-HeavyItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roobert";
  src: url("./Roobert-Heavy.woff2") format("woff2"), url("./Roobert-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roobert";
  src: url("./Roobert-Bold.woff2") format("woff2"), url("./Roobert-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roobert";
  src: url("./Roobert-Light.woff2") format("woff2"), url("./Roobert-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roobert";
  src: url("./Roobert-RegularItalic.woff2") format("woff2"), url("./Roobert-RegularItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roobert";
  src: url("./Roobert-Regular.woff2") format("woff2"), url("./Roobert-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roobert";
  src: url("./Roobert-SemiBoldItalic.woff2") format("woff2"), url("./Roobert-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roobert";
  src: url("./Roobert-MediumItalic.woff2") format("woff2"), url("./Roobert-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Roobert";
  src: url("./Roobert-SemiBold.woff2") format("woff2"), url("./Roobert-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
